.mapContainer {
    height: 100vh;
}
.mapLegendContainer {
  position: absolute;
  top: 20px;
  left: 20px;
}
.mapboxgl-ctrl,
.mapboxgl-ctrl-group {
  left: 10px; 
}