.details {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);
  border: 1px solid #aaa;
  border-radius: 10px;
  position: fixed;
  top: 60px;
  right: 10px;
  display: flex;
  flex-direction: column;
  width: 240px;
  padding: 17px 13px;
  text-align: left;
  max-height: 80vh;
  overflow: hidden;
  box-sizing: border-box;
}

.details:hover {
  overflow-y: auto;
}

.details::-webkit-scrollbar {
  width: 6px;
}

.details::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.details::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.details::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}